#autocomplete {
    border: 1px solid;
    overflow: hidden;
    position: absolute;
    z-index: 100;
}

#autocomplete ul {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
}

#autocomplete li {
    background: #fff;
    color: #000;
    cursor: default;
    white-space: pre;
    zoom: 1;
}

html.js input.form-autocomplete {
    background-image: url(https://guillermoshairstudio.com/misc/throbber-inactive.png);
    background-position: 100% center;
    background-repeat: no-repeat;
}

html.js input.throbbing {
    background-image: url(https://guillermoshairstudio.com/misc/throbber-active.gif);
    background-position: 100% center;
}

html.js fieldset.collapsed {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    height: 1em;
}

html.js fieldset.collapsed .fieldset-wrapper {
    display: none;
}

fieldset.collapsible {
    position: relative;
}

fieldset.collapsible .fieldset-legend {
    display: block;
}

.form-textarea-wrapper textarea {
    display: block;
    margin: 0;
    width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body.drag {
    cursor: move;
}

.draggable a.tabledrag-handle {
    cursor: move;
    float: left;
    height: 1.7em;
    margin-left: -1em;
    overflow: hidden;
    text-decoration: none;
}

a.tabledrag-handle:hover {
    text-decoration: none;
}

a.tabledrag-handle .handle {
    background: url(https://guillermoshairstudio.com/misc/draggable.png) no-repeat 6px 9px;
    height: 13px;
    margin: -0.4em 0.5em;
    padding: 0.42em 0.5em;
    width: 13px;
}

a.tabledrag-handle-hover .handle {
    background-position: 6px -11px;
}

div.indentation {
    float: left;
    height: 1.7em;
    margin: -0.4em 0.2em -0.4em -0.4em;
    padding: 0.42em 0 0.42em 0.6em;
    width: 20px;
}

div.tree-child {
    background: url(https://guillermoshairstudio.com/misc/tree.png) no-repeat 11px center;
}

div.tree-child-last {
    background: url(https://guillermoshairstudio.com/misc/tree-bottom.png) no-repeat 11px center;
}

div.tree-child-horizontal {
    background: url(https://guillermoshairstudio.com/misc/tree.png) no-repeat -11px center;
}

.tabledrag-toggle-weight-wrapper {
    text-align: right;
}

table.sticky-header {
    background-color: #fff;
    margin-top: 0;
}

.progress .bar {
    background-color: #fff;
    border: 1px solid;
}

.progress .filled {
    background-color: #000;
    height: 1.5em;
    width: 5px;
}

.progress .percentage {
    float: right;
}

.ajax-progress {
    display: inline-block;
}

.ajax-progress .throbber {
    background: transparent url(https://guillermoshairstudio.com/misc/throbber-active.gif) no-repeat 0px center;
    float: left;
    height: 15px;
    margin: 2px;
    width: 15px;
}

.ajax-progress .message {
    padding-left: 20px;
}

tr .ajax-progress .throbber {
    margin: 0 2px;
}

.ajax-progress-bar {
    width: 16em;
}

.container-inline div,
.container-inline label {
    display: inline;
}

.container-inline .fieldset-wrapper {
    display: block;
}

.nowrap {
    white-space: nowrap;
}

html.js .js-hide {
    display: none;
}

.element-hidden {
    display: none;
}

.element-invisible {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
}

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
    position: static !important;
    clip: auto;
    overflow: visible;
    height: auto;
}

.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

* html .clearfix {
    height: 1%;
}

*:first-child+html .clearfix {
    min-height: 1%;
}

ul.menu {
    border: none;
    list-style: none;
    text-align: left;
}

ul.menu li {
    margin: 0 0 0 0.5em;
}

ul li.expanded {
    list-style-image: url(https://guillermoshairstudio.com/misc/menu-expanded.png);
    list-style-type: circle;
}

ul li.collapsed {
    list-style-image: url(https://guillermoshairstudio.com/misc/menu-collapsed.png);
    list-style-type: disc;
}

ul li.leaf {
    list-style-image: url(https://guillermoshairstudio.com/misc/menu-leaf.png);
    list-style-type: square;
}

li.expanded,
li.collapsed,
li.leaf {
    padding: 0.2em 0.5em 0 0;
    margin: 0;
}

li a.active {
    color: #000;
}

td.menu-disabled {
    background: #ccc;
}

ul.inline,
ul.links.inline {
    display: inline;
    padding-left: 0;
}

ul.inline li {
    display: inline;
    list-style-type: none;
    padding: 0 0.5em;
}

.breadcrumb {
    padding-bottom: 0.5em;
}

ul.primary {
    border-bottom: 1px solid #bbb;
    border-collapse: collapse;
    height: auto;
    line-height: normal;
    list-style: none;
    margin: 5px;
    padding: 0 0 0 1em;
    white-space: nowrap;
}

ul.primary li {
    display: inline;
}

ul.primary li a {
    background-color: #ddd;
    border-color: #bbb;
    border-style: solid solid none solid;
    border-width: 1px;
    height: auto;
    margin-right: 0.5em;
    padding: 0 1em;
    text-decoration: none;
}

ul.primary li.active a {
    background-color: #fff;
    border: 1px solid #bbb;
    border-bottom: 1px solid #fff;
}

ul.primary li a:hover {
    background-color: #eee;
    border-color: #ccc;
    border-bottom-color: #eee;
}

ul.secondary {
    border-bottom: 1px solid #bbb;
    padding: 0.5em 1em;
    margin: 5px;
}

ul.secondary li {
    border-right: 1px solid #ccc;
    display: inline;
    padding: 0 1em;
}

ul.secondary a {
    padding: 0;
    text-decoration: none;
}

ul.secondary a.active {
    border-bottom: 4px solid #999;
}

div.messages {
    background-position: 8px 8px;
    background-repeat: no-repeat;
    border: 1px solid;
    margin: 6px 0;
    padding: 10px 10px 10px 50px;
}

div.status {
    background-image: url(https://guillermoshairstudio.com/misc/message-24-ok.png);
    border-color: #be7;
}

div.status,
.ok {
    color: #234600;
}

div.status,
table tr.ok {
    background-color: #f8fff0;
}

div.warning {
    background-image: url(https://guillermoshairstudio.com/misc/message-24-warning.png);
    border-color: #ed5;
}

div.warning,
.warning {
    color: #840;
}

div.warning,
table tr.warning {
    background-color: #fffce5;
}

div.error {
    background-image: url(https://guillermoshairstudio.com/misc/message-24-error.png);
    border-color: #ed541d;
}

div.error,
.error {
    color: #8c2e0b;
}

div.error,
table tr.error {
    background-color: #fef5f1;
}

div.error p.error {
    color: #333;
}

div.messages ul {
    margin: 0 0 0 1em;
    padding: 0;
}

div.messages ul li {
    list-style-image: none;
}

fieldset {
    margin-bottom: 1em;
    padding: 0.5em;
}

form {
    margin: 0;
    padding: 0;
}

hr {
    border: 1px solid gray;
    height: 1px;
}

img {
    border: 0;
}

table {
    border-collapse: collapse;
}

th {
    border-bottom: 3px solid #ccc;
    padding-right: 1em;
    text-align: left;
}

tbody {
    border-top: 1px solid #ccc;
}

tr.even,
tr.odd {
    background-color: #eee;
    border-bottom: 1px solid #ccc;
    padding: 0.1em 0.6em;
}

th.active img {
    display: inline;
}

td.active {
    background-color: #ddd;
}

.item-list .title {
    font-weight: bold;
}

.item-list ul {
    margin: 0 0 0.75em 0;
    padding: 0;
}

.item-list ul li {
    margin: 0 0 0.25em 1.5em;
    padding: 0;
}

.form-item,
.form-actions {
    margin-top: 1em;
    margin-bottom: 1em;
}

tr.odd .form-item,
tr.even .form-item {
    margin-top: 0;
    margin-bottom: 0;
    white-space: nowrap;
}

.form-item .description {
    font-size: 0.85em;
}

label {
    display: block;
    font-weight: bold;
}

label.option {
    display: inline;
    font-weight: normal;
}

.form-checkboxes .form-item,
.form-radios .form-item {
    margin-top: 0.4em;
    margin-bottom: 0.4em;
}

.form-type-radio .description,
.form-type-checkbox .description {
    margin-left: 2.4em;
}

input.form-checkbox,
input.form-radio {
    vertical-align: middle;
}

.marker,
.form-required {
    color: #f00;
}

.form-item input.error,
.form-item textarea.error,
.form-item select.error {
    border: 2px solid red;
}

.container-inline .form-actions,
.container-inline.form-actions {
    margin-top: 0;
    margin-bottom: 0;
}

.more-link {
    text-align: right;
}

.more-help-link {
    text-align: right;
}

.more-help-link a {
    background: url(https://guillermoshairstudio.com/misc/help.png) 0 50% no-repeat;
    padding: 1px 0 1px 20px;
}

.item-list .pager {
    clear: both;
    text-align: center;
}

.item-list .pager li {
    background-image: none;
    display: inline;
    list-style-type: none;
    padding: 0.5em;
}

.pager-current {
    font-weight: bold;
}

#autocomplete li.selected {
    background: #0072b9;
    color: #fff;
}

html.js fieldset.collapsible .fieldset-legend {
    background: url(https://guillermoshairstudio.com/misc/menu-expanded.png) 5px 65% no-repeat;
    padding-left: 15px;
}

html.js fieldset.collapsed .fieldset-legend {
    background-image: url(https://guillermoshairstudio.com/misc/menu-collapsed.png);
    background-position: 5px 50%;
}

.fieldset-legend span.summary {
    color: #999;
    font-size: 0.9em;
    margin-left: 0.5em;
}

tr.drag {
    background-color: #fffff0;
}

tr.drag-previous {
    background-color: #ffd;
}

.tabledrag-toggle-weight {
    font-size: 0.9em;
}

body div.tabledrag-changed-warning {
    margin-bottom: 0.5em;
}

tr.selected td {
    background: #ffc;
}

td.checkbox,
th.checkbox {
    text-align: center;
}

.progress {
    font-weight: bold;
}

.progress .bar {
    background: #ccc;
    border-color: #666;
    margin: 0 0.2em;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}


.flexslider .slides img {
    height: 450px !important;
    width: 100% !important;
    object-fit: cover;
    object-position: center;
}